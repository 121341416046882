import React from 'react'
import styled from 'styled-components'

export default class Logo extends React.Component {
  render() {
    let houseColor = 'white'
    let textColor = 'white'

    let colorFirst = '#a58a6d'
    let colorSecond = '#b68639'

    const LogoContainer = styled.div`
      .cls-1 {
        fill: #927237;
      }
    `

    return (
      <LogoContainer>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1175.91 348.92">
          <defs />
          <title>Kibuk Construction Logo</title>
          <path
            className="cls-1"
            d="M24.54,188.18c10.52,1.7,19.79,3.28,29.09,4.68,17.34,2.62,34.67,5.42,52.07,7.64,22.24,2.84,44.51,5.51,66.83,7.64,17,1.63,34.17,2.55,51.27,3.34,18.12.83,36.27,1.69,54.4,1.54,24.45-.2,48.9-1.13,73.34-2.19,16.1-.7,32.19-1.89,48.24-3.37C416,206,432.14,204,448.29,202q23.94-3,47.81-6.45c17.56-2.58,35.06-5.49,52.59-8.22,19-3,38-5.84,57.06-8.82,15-2.37,30-5.08,45.13-7.19,13.45-1.89,27-3.29,40.5-4.68,14.19-1.46,28.39-2.82,42.61-3.9,9.44-.72,18.91-1,28.38-1.21,20.63-.41,41.29-1.38,61.89-.74,27.07.84,54.16,2.33,81.13,4.8,25.77,2.35,51.57,5.32,77.05,9.81,37.2,6.56,74.33,13.86,111.12,22.42,36,8.38,71.46,18.74,106.9,29.32-11.34-1.39-22.66-2.93-34-4.12-23.12-2.42-46.24-5-69.42-6.85-29.34-2.31-58.72-4.61-88.13-5.67-30.61-1.11-61.29-1.58-91.9-.78-34.24.9-68.48,2.9-102.62,5.64-28.46,2.29-56.83,6-85.15,9.69-40.13,5.3-80.14,11.51-120.27,16.81-27.15,3.58-54.37,6.71-81.64,9.18-23.33,2.11-46.77,3.25-70.18,4.29-11.43.51-22.91-.29-34.36-.34-12.45-.06-24.93.55-37.35,0-18.43-.83-36.86-2.06-55.22-3.8-21-2-42-4.09-62.86-7.28-25.4-3.89-50.75-8.37-75.9-13.67-32.39-6.82-64.65-14.35-96.8-22.24-22.53-5.53-44.8-12.16-67.19-18.32A25.07,25.07,0,0,1,24.54,188.18Z"
            transform="translate(-24.54 -1.54)"
          />
          <path
            d="M119.68,1.54a55.68,55.68,0,0,1,.63,5.87c0,15.81,0,31.63,0,48.73,2-1.67,3.43-2.63,4.62-3.78,15-14.46,29.88-29,45-43.33A11.83,11.83,0,0,1,177.23,6c16.76-.27,33.52-.14,51.41-.14l-70,67.35c3.52.75,6,1.23,8.51,1.82,28.52,6.72,45.8,25.47,55.45,52.18,6.12,16.92,8.41,34.52,8.18,52.44a5.08,5.08,0,0,1-2.57,3.8c-11.24,4.49-22.58,8.71-33.4,12.83,0-8,.21-15.9,0-23.82-1.29-41.78-28.37-66.7-66.63-72.88-2.61-.42-5.26-.63-8.4-1v88.69L83,198.09v-6.25q0-92.47,0-185a40.55,40.55,0,0,1,.68-5.35Z"
            transform="translate(-24.54 -1.54)"
          />
          <path
            d="M652.68,1.54V56.72c2.29-2,3.6-3.15,4.84-4.33q22.89-22,45.8-43.92c1.23-1.18,3-2.49,4.58-2.51,17.28-.18,34.56-.11,53.12-.11L691.13,73.16c5,1.17,8.85,1.89,12.61,3,28.41,8.29,44.27,28.62,52.88,55.66,4,12.4,6.36,25.12,6.06,38.25-.09,3.87,1.61,8.7-.14,11.37s-6.75,3.48-10.4,4.89c-8.22,3.17-16.49,6.21-25.22,9.49,3-30.58-.5-58.65-25.75-79.44C688.69,106.09,665.08,97.83,651.7,99v5.27c0,26-.14,52,0,78,0,3.5-1.07,5-4.3,5.9-10.37,2.93-20.67,6.09-31.73,9.38V1.54Z"
            transform="translate(-24.54 -1.54)"
          />
          <path
            d="M392.59,78c5.22,1.43,9.67,2.25,13.83,3.84,16.35,6.27,25.16,18.71,27.08,35.72a63.65,63.65,0,0,1-36.17,64.39,15.06,15.06,0,0,1-6.16,1.57q-52.23.15-104.46.06c-.8,0-1.6-.12-2.69-.21V6.57h26.19c19.49,0,39-.65,58.47.17a66.51,66.51,0,0,1,39.73,15.35c9.28,7.64,11.18,18,4.76,29.2-4.58,8-11,14.9-16.63,22.26C395.46,75,394.18,76.21,392.59,78ZM321,39.57V150.86c17.89,0,35.39.19,52.87-.07,13.15-.2,24-10,24.33-21.78.21-8.66-3.24-16.12-8.91-22.64-4.77-5.47-10.34-8.32-17.92-7.93-9.8.52-19.65.13-29.49.13H331.06c5.73-6,10.52-11,15.34-16,9.46-9.85,19.11-19.53,28.26-29.67,1.92-2.14,3.59-6.77,2.54-8.76-1.25-2.36-5.46-4-8.56-4.35-6.43-.64-13-.18-19.48-.17Z"
            transform="translate(-24.54 -1.54)"
          />
          <path
            d="M444.66,6h37.1v5.81c0,40.33.22,80.67-.18,121-.08,7.89,3.33,12.06,9.71,15a75.16,75.16,0,0,0,40.42,6.13,100.85,100.85,0,0,0,23-5.68c6.77-2.48,10.18-7.51,10.09-15.78-.46-40.16-.21-80.33-.21-120.49V6.15h36.88v5.47c0,45.16-.11,90.33.12,135.5a20.21,20.21,0,0,1-6.25,15.07c-9.14,9.38-20.67,14.4-33.06,17.55-31.1,7.91-61.92,7.69-92.07-4.32a63.84,63.84,0,0,1-13.38-7.58c-8.19-5.89-12.66-13.12-12.49-24.29.68-43.82.28-87.66.28-131.49Z"
            transform="translate(-24.54 -1.54)"
          />
          <path
            d="M240.92,6.66h36.2V183.78l-36.2,9Z"
            transform="translate(-24.54 -1.54)"
          />
          <path
            d="M293.68,337.56c.57-3.61,1-6.53,1.58-9.88,5,.47,9.94,1.48,14.8,1.26a169.73,169.73,0,0,0,21.56-2.43c4-.68,5.91-3.87,6.27-8,.37-4.29-1.59-7.13-5.29-8.68-8-3.33-16.06-6.33-24-9.68-6.89-2.9-12.48-6.79-12-15.66.51-8.64,4.4-14.65,12.89-17.65,4.19-1.49,8.65-3.08,13-3.11,32.31-.26,64.63-.14,97-.14-3.08,9.39-3.93,10-13.43,10H392.72V339.1H380.94V274c-1.88-.14-3.31-.34-4.75-.35-16.32,0-32.65-.21-49,0a44.11,44.11,0,0,0-12.64,2.3c-3.66,1.17-5.87,4-6.07,8.28s2.73,5.79,5.81,7.09c8.25,3.5,16.61,6.75,24.85,10.27,7.13,3.05,10.94,8.39,10.61,16.38s-3.66,13.51-11,16.88c-9.55,4.37-19.56,5-29.77,4.31C304,338.82,299.1,338.13,293.68,337.56Z"
            transform="translate(-24.54 -1.54)"
          />
          <path
            d="M717.6,263.55a11,11,0,0,1-.09,1.24c-2.64,7-7.29,10.28-15.12,8.93-3.87-.67-7.95-.12-12.58-.12v65.55H677.89V274.88c-.73-.63-1-1-1.24-1-17.45.18-34.94-.2-52.34.92-5.88.38-11.89,4-17.2,7.13-12.12,7.27-12.21,32.44,1.33,40.56,9.74,5.85,21.13,8,36.16,5.23.78,3.06,1.58,6.16,2.46,9.61-18.29,4.26-35.12,2.25-49.6-9.86-17.22-14.41-14.31-44.12,5.18-55.61a57.67,57.67,0,0,1,29.87-8.31c26.65.08,53.29,0,79.94,0Z"
            transform="translate(-24.54 -1.54)"
          />
          <path
            d="M469.23,320.15l31.31,10.93c-.49,2.22-1.18,5.32-2,8.83-21-3.66-39.68-10.89-55-26a16.61,16.61,0,0,0,2.17-3c1.77-4,4.55-4,8.4-3.06,9.15,2.16,17.52-.25,24.7-6.17,5.85-4.82,6.58-14.51.89-19.5-3.83-3.37-8.84-6.77-13.67-7.35-9.46-1.15-19.16-.34-29.33-.34v64.59H425.06V264.69c14.56,0,29-.8,43.36.38,6.16.51,12.73,4.16,17.83,8.07,13.77,10.55,11.72,30.08-3.67,39.1-4.07,2.39-8.56,4.05-12.86,6.05C469.56,318.91,469.4,319.53,469.23,320.15Z"
            transform="translate(-24.54 -1.54)"
          />
          <path
            d="M910.32,339.22H898.69c0-4.09-.38-8.07.08-12,1.11-9.41-2.32-17.13-7.79-24.44a68.94,68.94,0,0,0-38-26,37.4,37.4,0,0,0-4.89-.64v62.88h-11.9v-75c22.88,1.33,35,6.81,62.23,28.37V264.11h11.85Z"
            transform="translate(-24.54 -1.54)"
          />
          <path
            d="M210.72,264.13c25.64,2.45,36,7.14,61.64,28.16V264.06h12.05v75H272.72c0-6.43.09-12.71-.05-19a15.55,15.55,0,0,0-1.13-6.24c-9.24-19.32-24.47-31.51-45-37.19a22.19,22.19,0,0,0-4-.32v62.7H210.72Z"
            transform="translate(-24.54 -1.54)"
          />
          <path
            d="M1009.13,263.94c23.77,2.26,44.79,10.27,62,29.47V264h11.57v75.25h-11.26c0-3.46-.37-6.91.07-10.26,1.4-10.9-2.83-19.73-9.37-27.94a69.19,69.19,0,0,0-37.3-24.38,25.91,25.91,0,0,0-3.93-.36V339h-11.81Z"
            transform="translate(-24.54 -1.54)"
          />
          <path
            d="M118.45,301.28c.12-13.21,6.28-23.24,16.86-30.57,17.18-11.91,42.19-8.81,55.78,7.06,9.1,10.62,12,22.85,6.7,36.24-5.36,13.64-15.6,21.84-29.87,24.38-17.25,3.07-32-1.68-42.75-16.12A32.42,32.42,0,0,1,118.45,301.28Zm11.91.21c-.93,12.71,10.07,28,28.91,27.8,19.3-.18,29.14-14.36,29.29-28.27.16-14.59-13.33-27.21-28.71-27.38C143.22,273.45,130.41,285.55,130.36,301.49Z"
            transform="translate(-24.54 -1.54)"
          />
          <path
            d="M826.22,299.13c-.81,18.05-8.53,29.25-22.53,36.13-16.19,8-37.76,4-49.24-8.69-9.57-10.61-13-22.89-8.29-36.44,4.82-13.94,15.11-22.57,29.59-25.44,20.56-4.09,39.15,4.74,47,22.23C824.75,291.36,825.47,296.39,826.22,299.13Zm-41-25.48c-17.4.41-29.22,12.46-29.24,28.29,0,13.38,10.06,27.18,29.07,27.29,17.75.1,29.33-12.57,29.22-28.15C814.17,286.33,802.73,274.09,785.23,273.65Z"
            transform="translate(-24.54 -1.54)"
          />
          <path
            d="M510.55,263.8h11.82v16.59c0,10.82.2,21.65-.07,32.46-.15,5.71,2.27,9.51,7,12.21,10,5.75,19.87,5.48,29.62-.59A12,12,0,0,0,565,313.3c-.18-14.82-.06-29.63-.06-44.45v-5.07h11.9v12.36c0,11.48-.46,23,.13,34.45.64,12.45-5.31,20-16,24.93-14.16,6.56-32.42,4.2-43.53-5.74-4.3-3.84-6.86-8.52-6.87-14.38C510.53,298.29,510.55,281.17,510.55,263.8Z"
            transform="translate(-24.54 -1.54)"
          />
          <path
            d="M110.81,265.77c-.88,3.41-1.65,6.42-2.43,9.46-10.24-1.15-20.11-2.07-29.71,2-11.58,5-17.11,12.91-17,25,.13,11.28,6.31,19.62,17.76,23.95,9.32,3.52,18.88,2.95,28.91,1.57.75,3,1.52,6,2.28,9-16.9,6.83-42.84.19-53.74-13.69-12.21-15.54-8.31-39.65,8.29-50.41C79.27,263.51,94.59,262.47,110.81,265.77Z"
            transform="translate(-24.54 -1.54)"
          />
          <path
            d="M1153.61,265.73c-.84,3.46-1.57,6.48-2.3,9.51-10.4-1.16-20.45-2.11-30.2,2.2-11.43,5.05-16.74,12.76-16.55,25.2.17,11.53,6.49,18.76,16.75,23.1,9.59,4.06,19.47,3.47,29.94,1.95.75,3.05,1.51,6.16,2.38,9.68-19,4.07-36.42,2.29-50.94-11.43-14.67-13.86-13.25-39.38,2.85-51.54C1119.94,263.52,1136.3,262.37,1153.61,265.73Z"
            transform="translate(-24.54 -1.54)"
          />
          <path
            d="M723,264h11.15V339H723Z"
            transform="translate(-24.54 -1.54)"
          />
          <path
            d="M985.92,263.89h11.13V339H985.92Z"
            transform="translate(-24.54 -1.54)"
          />
          <path
            d="M931,326.44l10.24,5.28-12.38,18.74-10.15-5.25Z"
            transform="translate(-24.54 -1.54)"
          />
          <path
            d="M1164.07,328.83h11.15v10.39h-11.15Z"
            transform="translate(-24.54 -1.54)"
          />
        </svg>
      </LogoContainer>
    )
  }
}
