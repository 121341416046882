import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { Check } from 'react-feather'

import YelpIcon from '../assets/icons/yelp'
import FacebookIcon from '../assets/icons/facebook'
import InstagramIcon from '../assets/icons/instagram'
import GoogleIcon from '../assets/icons/google'
import YoutubeIcon from '../assets/icons/youtube'

const FooterOuterContainer = styled.div`
  background: #fff;
  padding: 3em 0;
  padding-bottom: 2em;
  border-bottom: 20px solid;
`
const CopyrightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgba(255, 255, 255, 0.09);
  padding-top: 1em;
`
const CopyrightText = styled.p`
  color: #757575;
  text-align: center;
  margin: 0;
  padding: 0.5em;
  font-style: italic;
`
const SidebarHeader = styled.h4`
  margin-bottom: 0.5em;
`
const SidebarP = styled.p`
  color: #444;
`
const FooterLinksMenu = styled.ul``

const WhyUsUnorderedList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: relative;
  li {
    list-style-type: none;
    padding: 0;
    padding-left: 25px;
    line-height: 1.4;
    position: relative;
    svg {
      border-radius: 50%;
      padding-top: 5px;
      stroke-width: 5px;
      margin-left: -30px;
      padding: 4px;
      position: absolute;
      top: 2px;
    }
  }
`

const SocialMediaIcon = styled.div`
  path {
    fill: #444;
  }
  a {
    svg {
      height: 24px;
    }
  }
`

const SocialMediaIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 300px;
  margin: 0 auto;
`

const CompanyInfoColumn = props => {
  let { siteMetadata } = props
  return (
    <>
      {siteMetadata.companyInfo.license ? (
        <div>
          <SidebarHeader>Licensed &amp; Insured</SidebarHeader>
          <SidebarP>License #: {siteMetadata.companyInfo.license}</SidebarP>
        </div>
      ) : null}

      <SidebarHeader>Contact Us</SidebarHeader>
      <SidebarP>
        {siteMetadata.companyInfo.phone}
        <br />
        {siteMetadata.companyInfo.email}
      </SidebarP>

      <SidebarHeader>Location</SidebarHeader>
      <SidebarP>
        {siteMetadata.companyInfo.addressLine1}
        <br />
        {siteMetadata.companyInfo.city}, {siteMetadata.companyInfo.state}{' '}
        {siteMetadata.companyInfo.zip}
      </SidebarP>
    </>
  )
}

const WhyUs = props => {
  return (
    <>
      <h4>Why Us</h4>
      <WhyUsUnorderedList>
        {props.siteMetadata.whyUs.map((item, index) => (
          <li key={index}>
            <Check size={22} className="text-color-primary" />
            {item}
          </li>
        ))}
      </WhyUsUnorderedList>
    </>
  )
}

const FooterColumms = footerData => {
  const footerColumns = footerData.data.map((footer, index) => (
    <FooterColumm
      key={index}
      footerInfo={footer}
      columnsCount={footerData.data.length}
      siteData={footerData.siteData.site}
    />
  ))
  return footerColumns
}

const FooterColumm = footerInfo => {
  if (
    footerInfo.footerInfo.node &&
    footerInfo.footerInfo.node.html &&
    footerInfo.footerInfo.node.html.html.includes('{{CompanyInfo}}')
  ) {
    return (
      <div
        className={'mb-4 col-12 col-md-' + 12 / footerInfo.columnsCount}
        style={{
          order:
            footerInfo.footerInfo &&
            footerInfo.footerInfo.node &&
            footerInfo.footerInfo.node.order,
        }}
      >
        <CompanyInfoColumn siteMetadata={footerInfo.siteData.siteMetadata} />
      </div>
    )
  }
  if (
    footerInfo.footerInfo.node &&
    footerInfo.footerInfo.node.html &&
    footerInfo.footerInfo.node.html.html.includes('{{WhyUs}}')
  ) {
    return (
      <div
        className={'mb-4 col-12 col-md-' + 12 / footerInfo.columnsCount}
        style={{
          order:
            footerInfo.footerInfo &&
            footerInfo.footerInfo.node &&
            footerInfo.footerInfo.node.order,
        }}
      >
        <WhyUs siteMetadata={footerInfo.siteData.siteMetadata} />
      </div>
    )
  }

  if (footerInfo.footerInfo.node.menuLinks) {
    const footerLinksMapped = footerInfo.footerInfo.node.menuLinks.map(
      (menuItem, index) => (
        <li key={index}>
          <Link
            to={menuItem.url ? menuItem.url : '#'}
            title={menuItem.title}
            activeClassName={'active'}
          >
            {menuItem.title}
          </Link>
        </li>
      )
    )
    return (
      <div
        className={'mb-4 col-12 col-md-' + 12 / footerInfo.columnsCount}
        style={{
          order:
            footerInfo.footerInfo &&
            footerInfo.footerInfo.node &&
            footerInfo.footerInfo.node.order,
        }}
      >
        <h4>{footerInfo.footerInfo.node.title}</h4>
        <FooterLinksMenu>{footerLinksMapped}</FooterLinksMenu>
      </div>
    )
  }

  return (
    <div
      className={'mb-4 col-12 col-md-' + 12 / footerInfo.columnsCount}
      style={{
        order:
          footerInfo.footerInfo &&
          footerInfo.footerInfo.node &&
          footerInfo.footerInfo.node.order,
      }}
    >
      <h4>{footerInfo.footerInfo.node.title}</h4>
      {footerInfo.footerInfo.node &&
      footerInfo.footerInfo.node.html &&
      footerInfo.footerInfo.node.html.html ? (
        <div
          dangerouslySetInnerHTML={{
            __html: footerInfo.footerInfo.node.html.html,
          }}
        />
      ) : (
        ''
      )}
    </div>
  )
}

const Footer = () => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        site {
          siteMetadata {
            title
            whyUs
            companyInfo {
              phone
              email
              addressLine1
              addressLine2
              city
              state
              zip
              license
            }
            social {
              yelp
              facebook
              instagram
              youtube
              google
            }
          }
        }
        allContentfulFooterFooterColumn {
          edges {
            node {
              id
              title
              order
              menuLinks {
                id
                name
                title
                url
                openInNewWindow
                submenu {
                  id
                  name
                  menuItems {
                    id
                    name
                    title
                    url
                    openInNewWindow
                  }
                }
              }
              html {
                html
              }
            }
          }
        }
      }
    `}
    render={function(data) {
      //assuming length of array equals columns intended
      let footerData = data.allContentfulFooterFooterColumn.edges

      let { siteMetadata } = data.site

      return (
        <FooterOuterContainer className="container-fluid bt-color-primary">
          <div className="container">
            <div className="row">
              <FooterColumms data={footerData} siteData={data} />
            </div>
          </div>

          <SocialMediaIcons>
            {siteMetadata.social.yelp ? (
              <SocialMediaIcon>
                <a href={siteMetadata.social.yelp} target="_blank">
                  <YelpIcon />
                </a>
              </SocialMediaIcon>
            ) : (
              ''
            )}

            {siteMetadata.social.facebook ? (
              <SocialMediaIcon>
                <a href={siteMetadata.social.facebook} target="_blank">
                  <FacebookIcon />
                </a>
              </SocialMediaIcon>
            ) : (
              ''
            )}

            {siteMetadata.social.google ? (
              <SocialMediaIcon>
                <a href={siteMetadata.social.google} target="_blank">
                  <GoogleIcon />
                </a>
              </SocialMediaIcon>
            ) : (
              ''
            )}

            {siteMetadata.social.youtube ? (
              <SocialMediaIcon>
                <a href={siteMetadata.social.youtube} target="_blank">
                  <YoutubeIcon />
                </a>
              </SocialMediaIcon>
            ) : (
              ''
            )}

            {siteMetadata.social.instagram ? (
              <SocialMediaIcon>
                <a href={siteMetadata.social.instagram} target="_blank">
                  <InstagramIcon />
                </a>
              </SocialMediaIcon>
            ) : (
              ''
            )}
          </SocialMediaIcons>

          <CopyrightContainer>
            <CopyrightText>
              Copyright &copy; {data.site.siteMetadata.title}{' '}
              {new Date().getFullYear()}. All Rights Reserved.
            </CopyrightText>
          </CopyrightContainer>
        </FooterOuterContainer>
      )
    }}
  />
)

export default Footer
