import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

const ButtonOuter = styled.button`
  padding: 0.4em 1.25em;
  font-size: inherit;
  color: white;
  display: inline-block;
  text-align: center;
  line-height: inherit;
  font-weight: 600;
  margin: 0px;
  text-decoration: none;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  border-radius: 0;
  font-family: 'Camber', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: bold;
  cursor: pointer;
`

const ButtonOuterLink = styled(Link)`
  padding: 0.5em 1.35em;
  font-size: inherit;
  color: white;
  display: inline-block;
  text-align: center;
  line-height: inherit;
  font-weight: 600;
  margin: 0px;
  text-decoration: none;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  font-family: 'Camber', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: bold;
  border-radius: 0;
`

class Button extends React.Component {
  render() {
    return (
      <>
        {this.props.to ? (
          <ButtonOuterLink
            title={
              this.props && this.props.title
                ? this.props.title
                : this.props.children
            }
            to={this.props.to}
            className={
              'bg-color-' +
              this.props.color +
              ' hover:bg-color-' +
              this.props.color +
              '-dark color-' +
              (this.props.text ? this.props.text : 'white') +
              ' hover:color-' +
              (this.props.text ? this.props.text + '-dark' : 'white') +
              (this.props.className ? ' ' + this.props.className : '')
            }
          >
            {this.props.children
              ? this.props.children
              : '@TODO Add Button Content'}
          </ButtonOuterLink>
        ) : (
          <ButtonOuter
            role={this.props.role}
            type={this.props.type}
            className={
              'bg-color-' +
              this.props.color +
              ' hover:bg-color-' +
              this.props.color +
              '-dark color-' +
              (this.props.text ? this.props.text : 'white') +
              ' hover:color-' +
              (this.props.text ? this.props.text + '-dark' : 'white') +
              (this.props.className ? ' ' + this.props.className : '')
            }
          >
            {this.props.children
              ? this.props.children
              : '@TODO Add Button Content'}
          </ButtonOuter>
        )}
      </>
    )
  }
}

export default Button
